<template>
  <div class="element-div">
    <div class="flex card-content-item attr-box">
      <div class="flex prop-box">
        <div class="text_hidden propname">图案</div>
        <div class="flex propvalue">
          <el-select
            v-model="elementData.list[0].value"
            @change="entryInput(elementData.list[0])"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, idx) in elementData.list[0].list"
              :key="idx"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="flex card-content-item attr-box">
      <div class="flex prop-box">
        <div class="text_hidden propname">比例</div>
        <div class="flex propvalue">
          <div
            class="input-item-box"
            :class="{
              p15: !elementData.list[1].showInput
            }"
            @dblclick="onShowInput(elementData.list[1])"
          >
            <el-input
              v-if="elementData.list[1] && elementData.list[1].showInput"
              v-model="elementData.list[1].value"
              placeholder="请输入内容"
              ref="input"
              @keyup.enter.native="entryInput(elementData.list[1])"
              @blur="() => entryInput(elementData.list[1])"
            ></el-input>
            <template v-else>
              {{ elementData.list[1].value }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LTHatch",
  props: {
    pixiMap: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    featureDatas: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      elementData: this.resetData()
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 数据赋值
    initData(datas) {
      let hatchs = this.pixiMap.dwgservice._hatchs;
      this.elementData.list[0].list = Array.from(hatchs.keys());
      if (datas.length == 1) {
        let it_geometry = datas[0];
        this.elementData.list[0].value =
          it_geometry._entity_data._hatchname || "";
        this.elementData.list[1].value =
          it_geometry._entity_data._patternScale || 0;
      } else {
        this.elementData.list.map(it => {
          let list = [],
            isMore = false;

          datas.some(item => {
            if (it.sname == "_hatchname") {
              list.push(item._entity_data._hatchname || "");
            } else list.push(item._entity_data._patternScale || 0);
            if (list.length >= 2 && Array.from(new Set(list)).length > 1) {
              it.value = "多种";
              isMore = true;
              return true;
            }
          });
          if (!isMore) it.value = list[0];
          return it;
        });
      }
    },
    // 重置数据
    resetData() {
      return {
        name: "面",
        sname: "LTHatch",
        type: "moreInput",
        show: false,
        value: "",
        list: [
          {
            name: "图案",
            value: "",
            sname: "_hatchname",
            list: [],
            type: "select",
            typeName: ""
          },
          {
            name: "比例",
            value: "",
            sname: "hscale",
            type: "input",
            typeName: "number"
          }
        ]
      };
    },
    // 双击显示表单
    onShowInput(item) {
      if (item && item.disable) return;
      if (!item.showInput) {
        this.$set(item, "showInput", true);
        this.$nextTick(() => {
          this.$refs.input[0].focus();
        });
      } else item.showInput = false;
    },
    // 表单entry
    entryInput(ele) {
      this.$emit("entryInput", ele);
    }
  },
  watch: {
    featureDatas: {
      handler(newVal, oldVal) {
        this.elementData = this.resetData();
        if (newVal && newVal.length > 0 && oldVal != newVal) {
          this.initData(newVal);
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'

.element-div
  position relative
  width 100%
  height 100%
</style>
